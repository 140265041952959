
export const usePageState = defineStore('page', {
    state: () => ({
        title: '',
        notifications: [],
    }),
    actions: {
        setSelectAPlanNotification(){
            this.notifications = [{
                'type' : 'warning',
                'content' : 'Please <a class="font-semibold underline" href="/checkout/select-plan/">Click Here</a> To Select A Plan'
            }];
        },
        setPayInvoiceNotification(){
            this.notifications = [{
                'type' : 'warning',
                'content' : 'Your Most Recent Payment Was Unsuccessful - <a class="font-semibold underline" href="/my-account/pay-invoice/">Resolve Now</a>'
            }];
        },
        logOutNotification(){
            document.body.classList.add('unauthenticated');
            //
            // this.notifications = [{
            //     'type' : 'session_expired',
            //     'content' : 'Your Session Has Expired, Please <a class="font-semibold underline" href="/logout">Log Out</a> And Back In Again'
            // }];
        },
        setServerError(){
            this.notifications = [{
                'type' : 'error',
                'content' : 'Server Error'
            }];
        },
        clearNotifications(){
            this.notifications = [];
            document.body.classList.remove('unauthenticated');
        }
    }
})